<template>
    <b-card no-body>
      <b-card-header class="pb-50 cursor-pointer" @click="visible = !visible">
        <div class="d-flex justify-content-between">
          <h5>{{ $t("Filters") }}</h5>
          <feather-icon :icon="visible ? 'ArrowDownIcon' : 'ArrowUpIcon'" />
        </div>
      </b-card-header>
      <b-collapse v-model="visible">
        <b-card-body>
          <b-row>
            <b-col cols="12" md="3" class="mb-md-0 mb-2">
              <label>{{ $t("message.tableHeader.ip") }}</label>
              <b-form-input
                autofocus
                v-model.trim="filters.ip"
                @keyup.enter="searchFilter"
              />
            </b-col>
            <b-col cols="12" md="3" class="mb-md-0 mb-2">
              <label>{{ $t("message.tableHeader.attempt") }}</label>
              <b-form-input
                autofocus
                v-model.trim="filters.attempt"
                @keyup.enter="searchFilter"
              />
            </b-col>
            <b-col cols="12" md="3" class="mb-md-0 mb-2">
              <label>{{ $t("message.tableHeader.risk_level") }}</label>
              <b-form-input
                autofocus
                v-model.trim="filters.risk_level"
                @keyup.enter="searchFilter"
              />
            </b-col>
            <b-col cols="12" class="mb-md-0 mb-2 mt-2" align="end">
              <b-button variant="warning" @click="resetFilter" class="mr-1">
                {{ $t("Clear") }}
              </b-button>
              <b-button
                variant="primary"
                @click="searchFilter"
                :disabled="loading"
              >
                <span v-if="!loading">{{ $t("Search") }}</span>
                <span v-else>
                  <SpinnerLoading />
                </span>
              </b-button>
            </b-col>
          </b-row>
        </b-card-body>
      </b-collapse>
    </b-card>
  </template>
  
  <script>
  import {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BCollapse,
  } from "bootstrap-vue";
  import vSelect from "vue-select";
  
  import SpinnerLoading from "@/components/SpinnerLoading";
  
  export default {
    components: {
      BRow,
      BCol,
      BCard,
      BCardHeader,
      BCardBody,
      BFormInput,
      BButton,
      BCollapse,
      vSelect,
      SpinnerLoading,
    },
    props: {
      loading: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        filters: {
            ip: null,
            attempt: null,
            risk_level: null,
        },
        visible: false,
      };
    },
    methods: {
      searchFilter() {
        const filter = this.filters;
        let cont = 0;
        for (const iterator in filter) {
          if (this.filters[iterator] == "" || this.filters[iterator] == null) {
            cont++;
          }
        }
        if (Object.keys(filter).length == cont) this.$emit("filterPage", false);
        else this.$emit("filterPage", true);
  
        this.$emit("searchFilter", JSON.parse(JSON.stringify(this.filters)));
      },
      resetFilter() {
        const filter = JSON.parse(JSON.stringify(this.filters));
        for (const iterator in filter) {
          this.filters[iterator] = null;
        }
      },
    },
  };
  </script>
  