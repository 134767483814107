import { userAxios } from '../index'

const failSessionList = async (perPage) => {
    try {
        return await userAxios.get(`attempt_loging_risk/index/${perPage}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const failSessionDetail = async (id) => {
    try {
        return await userAxios.get(`attempt_loging_risk/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const failSessionPagination = async (perPage, page) => {
    try {
        return await userAxios.get(`attempt_loging_risk/index/${perPage}?page=${page}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const failSessionFilter = async (perPage, data) => {
    try {
        return await userAxios.post(`attempt_loging_risk/filter/${perPage}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

export default {
    failSessionList,
    failSessionPagination,
    failSessionFilter,
    failSessionDetail,
}