<template>
    <div>
      <FailSessionFilter
        v-if="$can('filter', 'module_system_settings.settings.admin_security.fail_sessions')"
        :loading="loading"
        @searchFilter="searchFilter"
        @filterPage="filterPage"
      />
      <ModalFailSessions
        v-if="conditionalModal"
        :failSession="itemSession"
        @cerrarModalFailSessions="cerrarModalFailSessions"
      />


      <b-card>
        <TitleTable titleTable="Fail Sessions" iconTable="HomeIcon" />
        <div class="m-1">
          <!-- Table Top -->
          <b-row>
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>{{ $t("Show") }}</label>
              <v-select
                v-model="perPage"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>{{ $t("Entries") }}</label>
            </b-col>
            <!-- {{ $t("Search") }} -->
            <b-col cols="12" md="6">
              <div class="d-flex align-items-center justify-content-end">
                <GoBack class="mr-2" />
              </div>
            </b-col>
          </b-row>
        </div>
  
        <b-table
          :items="items"
          :fields="fields"
          hover
          bordered
          responsive
          primary-key="id"
          class="position-relative"
          show-empty
          empty-text="No matching records found"
        >
          <template #cell(name)="data">
            <b-link
              :to="{
                name: 'admin-facilities-view',
                params: { id: data.item.id },
              }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.name }}
            </b-link>
          </template>
          <template #cell(risk_level_risk)="data">
            <b-badge
              :variant="getRiskColor(data.value)"
              class="badge-glow cursor-pointer"
            >
              {{ data.value }}
            </b-badge>
          </template>
          <template #cell(actions)="data">
            <b-button
              v-if="$can('show', 'module_system_settings.settings.admin_security.fail_sessions')"
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              v-b-tooltip.hover.top="'View Detail'"
              variant="flat-primary"
              class="btn-icon"
              @click="viewDetail(data.item)"
            >
              <feather-icon icon="EyeIcon" />
            </b-button>
          </template>
        </b-table>
        <PaginationTable
          :dataMeta="dataMeta"
          :totalUsers="totalUsers"
          :perPage="perPage"
          :currentPage="currentPage"
          @page-changed="pageChanged"
        />
      </b-card>
      <ToastNotification ref="toast" />
    </div>
  </template>
  
  <script>
  import {
    BCard,
    BRow,
    BCol,
    BTable,
    BBadge,
    BLink,
    BAvatar,
    BButton,
    BDropdown,
    BDropdownItem,
    VBTooltip,
  } from "bootstrap-vue";
  import Ripple from "vue-ripple-directive";
  import vSelect from "vue-select";
  
  import axiosFS from "@/services/admin/failSessions"
  
  import ToastNotification from "@/components/ToastNotification";
  import SpinnerLoading from "@/components/SpinnerLoading";
  import PaginationTable from "@/components/PaginationTable";
  import TitleTable from "@/components/TitleTable.vue";
  import GoBack from "@/components/ButtonBack.vue";
  
  import FailSessionFilter from "./FailSessionFilter.vue";
  import ModalFailSessions from "./ModalFailSessions.vue";
  
  import { ref } from "vue-demi";
  export default {
    components: {
      BCard,
      BRow,
      BCol,
      BTable,
      BBadge,
      BAvatar,
      BButton,
      BDropdown,
      BDropdownItem,
      BLink,
      FailSessionFilter,
      ModalFailSessions,
      ToastNotification,
      SpinnerLoading,
      PaginationTable,
      TitleTable,
      GoBack,
      vSelect,
    },
    directives: {
      "b-tooltip": VBTooltip,
      Ripple,
    },
    data() {
      return {
        fields: [
          // A virtual column that doesn't exist in items
          { key: "id", thClass: "px-3" },
          // A column that needs custom formatting
          { key: "ip", label: "IP Adress", sortable: true },
          { key: "attempt", label: "Attempts" },
          { key: "risk_level_data", label: "Risk Data", sortable: true },
          { key: "risk_level_risk", label: "Risk Level", sortable: true },
          { key: "actions", label: "Actions" },
        ],
        items: null,
        loading: false,
        dataMeta: { from: 1, to: 1, of: 1 },
        totalUsers: 0,
        currentPage: {
          page: 1
        },
        perPage: 10,
        perPageOptions: [10, 25, 50, 100],
        filtro: false,
        failSessionFilter: null,
        conditionalModal: false,
        itemSession: null,
      };
    },
    mounted() {
      this.getSessions();
    },
    watch: {
      perPage(newVal) {
        if (!this.filtro) {
          this.getSessions();
        } else {
          this.searchFilter(this.failSessionFilter);
        }
      },
    },
    methods: {
      getSessions() {
        axiosFS
          .failSessionList(this.perPage)
          .then(({ data, total, current_page, per_page, ...res }) => {
            this.items = this.parseDataItems(data);
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          });
      },
      pageChanged(page) {
        if (!this.filtro) {
          axiosFS
            .failSessionList(this.perPage, page)
            .then(({ data, total, current_page, ...res }) => {
              this.items = this.parseDataItems(data);
              this.totalUsers = total;
              this.dataMeta.of = total;
              this.dataMeta.from = res.from;
              this.dataMeta.to = res.to;
              this.currentPage.page = current_page;
            });
        } else {
          axiosFS
            .failSessionFilterPagination(this.perPage, page, this.failSessionFilter)
            .then(({ data, total, current_page, ...res }) => {
              this.items = this.parseDataItems(data);
              this.totalUsers = total;
              this.dataMeta.of = total;
              this.dataMeta.from = res.from;
              this.dataMeta.to = res.to;
              this.currentPage.page = current_page;
            });
        }
      },
      searchFilter(value) {
        if (this.filtro) {
          this.loading = true;
          this.failSessionFilter = value;
          axiosFS
            .failSessionFilter(this.perPage, value)
            .then(({ data, total, current_page, ...res }) => {
              this.loading = false;
              this.items = this.parseDataItems(data);
              this.totalUsers = total;
              this.dataMeta.of = total;
              this.dataMeta.from = res.from;
              this.dataMeta.to = res.to;
              this.currentPage.page = current_page;
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          this.getSessions();
        }
      },
      filterPage(value) {
        this.filtro = value;
      },
      viewDetail(item) {
        this.conditionalModal = true;
        this.itemSession = item;
      },
      cerrarModalFailSessions(value) {
        this.conditionalModal = value;
      },
      parseDataItems(data){
        return  data.map((item) => {
          const risk = item.risk_level.split('|');
          return {
            ...item,
            risk_level_data: risk[0],
            risk_level_risk: risk[1],
          }
        });
      },
      getRiskColor(risk){
        const riskData = {
          low: 'info',
          moderate: 'warning',
          several: 'danger'
        }
        return riskData[risk];
      }
    },
  };
  </script>
  
  <style>
  </style>