<template>
    <b-modal
      v-model="conditionalModal"
      centered
      size="lg"
      :title="'Fail Sessions from '+failSession.ip"
      ok-title="Ok"
      ok-only
      hide-footer
      no-close-on-backdrop
      @hide="onHide"
    >
      <b-table
        :items="items"
        :fields="fields"
        hover
        bordered
        responsive
        primary-key="id"
        class="position-relative"
        show-empty
        empty-text="No matching records found"
      >
        <template #cell(attempt_request_risk_data.email.risk)="data">
          <b-badge
            :variant="data.value ? 'danger' : 'info'"
            class="badge-glow cursor-pointer"
          >
            <span class="align-text-top text-capitalize">
              {{ data.value ? 'High' : 'Low' }}
            </span>
          </b-badge>
        </template>
      </b-table>
    </b-modal>
  </template>
  
  <script>
  import { 
    BModal, 
    BBadge,
    BTable,
  } from "bootstrap-vue";
  import axiosFS from "@/services/admin/failSessions"

  export default {
    components: {
      BModal,
      BBadge,
      BTable,
    },
    props: {
      failSession: {
        type: Object,
        required: true,
      },
    },
    mounted(){
      this.getDetails();
    },
    data() {
      return {
        conditionalModal: true,
        items: null,
        fields: [
          { key: "id", thClass: "px-3" },
          { key: "attempt_request_ip", label: "Ip" },
          { key: "attempt_request_risk_data.email.email", label: "Risk Data" },
          { key: "attempt_request_risk_data.email.risk", label: "Risk" },

        ]
      };
    },
    methods: {
      onHide() {
        this.$emit("cerrarModalFailSessions", false);
      },
      getDetails(){
        axiosFS.failSessionDetail(this.failSession.id).then((data) => {
          this.items = data.ip_attempt_login_data.map((item) => {
            return {
              ...item,
              attempt_request_risk_data: JSON.parse(item.attempt_request_risk_data)
            }
          })
        });
      },
    },
  };
  </script>
  
  <style scoped>
  .city{
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
  }
  </style>